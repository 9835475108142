import "/Users/k-tanaka/project/137/cocoo_app/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/k-tanaka/project/137/cocoo_app/node_modules/core-js/modules/es.promise.js";
import "/Users/k-tanaka/project/137/cocoo_app/node_modules/core-js/modules/es.object.assign.js";
import "/Users/k-tanaka/project/137/cocoo_app/node_modules/core-js/modules/es.promise.finally.js";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import moment from "moment";
// Import the plugin here
import VueApexCharts from "vue-apexcharts";
import { createPinia, PiniaVuePlugin } from "pinia";
import { Cognito } from "./cognito";
import * as Sentry from "@sentry/vue";
Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.APP_ENV,
  integrations: [Sentry.vueIntegration({
    tracingOptions: {
      trackComponents: true,
      hooks: ["mount", "update", "unmount"]
    }
  }), Sentry.browserTracingIntegration({
    router: router
  }), Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});
// Install the authentication plugin here
Vue.use(Cognito, {
  region: process.env.VUE_APP_COGNITO_REGION,
  userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID
});
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
var base = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});
Vue.prototype.$http = base;
moment.locale("ja");
Vue.prototype.$moment = moment;
Vue.use(VueApexCharts);
Vue.component("Apexchart", VueApexCharts);
Vue.use(PiniaVuePlugin);
var pinia = createPinia();
/* eslint-disable no-new */
new Vue({
  router: router,
  store: store,
  pinia: pinia,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");